import IconWrapper from '@components/IconWrapper'
import RoleBasedView from '@components/RoleBasedView'
import { SessionUserProps, StoreProps } from '@interfaces/StoreState'
import { getQueryParams } from '@utils/helpers'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import RouteLink from '../RouteLink'
import navigationRoutes from './navigationRoutes'
import { NavigationName, NavigationWrapper, Wrapper } from './style'

const Sidebar: React.FunctionComponent<SessionUserProps> = ({ user }) => {
  const [isActivityPage, setIsActivityPage] = useState(false)
  const router = useRouter()
  const route = get(router, 'route', '/')
  const startsWith = (fullPath: string, path: string) => fullPath.startsWith(path) || `${fullPath}/`.startsWith(path)
  const isSalesforceRoute = route?.toLowerCase().includes('/salesforce')
  const isSFStandardUser = route?.toLowerCase().includes('/sfstandard')

  let navigations = navigationRoutes

  const { isAdmin } = useSelector(({ session }: StoreProps) => ({
    isAdmin: session.isAdmin,
  }))

  useEffect(() => {
    setIsActivityPage(router?.route?.toLowerCase().includes('/activity'))
  }, [router])

  if (isEmpty(user)) {
    return null
  }

  if (isSalesforceRoute || isSFStandardUser) {
    const navigation = navigations.find(({ path }) => path === route)
    navigations = navigations.filter(({ id }) => !navigation?.hideRoutes?.includes(id))
  }

  return (
    <Wrapper isActivityPage={isActivityPage} isAdmin={isAdmin}>
      {navigations.map(({ id, name, Icon, path, role, longText, queryParams = [] }) => {
        let isActive = false
        const instructorPath = '/instructors'
        const schedIPath = '/scheduling/instructors'

        const pagePath = path || '/'
        let params = ''

        if (pagePath === '/') {
          isActive = pagePath === route
        } else if (pagePath === `${instructorPath}`) {
          isActive = instructorPath === route
        } else {
          const partialActive = startsWith(route, pagePath)
          isActive = partialActive && (id !== 'Scheduling' || (id === 'Scheduling' && !startsWith(route, schedIPath)))
        }

        if (queryParams?.length && queryParams.some((key) => getQueryParams(key))) {
          params = `?${queryParams.map((key) => `${key}=${getQueryParams(key)}`).join('&')}`
        }

        const Component = (
          <RouteLink key={id} path={`${pagePath}${params}`}>
            <NavigationWrapper active={isActive}>
              <IconWrapper>
                <Icon size="md" />
              </IconWrapper>
              <NavigationName data-e2e={id} longText={longText}>
                <FormattedMessage id={name} />
              </NavigationName>
            </NavigationWrapper>
          </RouteLink>
        )
        if (role) {
          return (
            <RoleBasedView key={id} configId={role}>
              {Component}
            </RoleBasedView>
          )
        } else {
          return Component
        }
      })}
    </Wrapper>
  )
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
})

export default connect(mapStateToProps)(Sidebar)
