import Dashboard from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/02-Dashboard/GaugeDashboard'
import Admin from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/04-Login-Logout/LoginKeys'
import Help from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/14-Alerts/QuestionCircle'
import Calendar from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/Calendar1'
import TargetPath from '@berlitz/streamline-icons/lib/regular/06-Business-Products/04-Strategy/TargetPath'
import BookCloseBookmark1 from '@berlitz/streamline-icons/lib/regular/11-Content/02-Books/BookCloseBookmark1'
import Bookmark from '@berlitz/streamline-icons/lib/regular/11-Content/02-Books/BookTarget'
import SchoolTeacher from '@berlitz/streamline-icons/lib/regular/36-School-Learning/01-School/SchoolTeacher'
import CalendarFavorite from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/CalendarFavorite'
import PerformanceIncrease1 from '@berlitz/streamline-icons/lib/regular/06-Business-Products/11-Performances/PerformanceIncrease1'

export default [
  {
    id: 'Dashboard',
    name: 'Dashboard',
    Icon: Dashboard,
    role: 'c::sidenav::dashboard::student',
  },
  {
    id: 'InstructorDashboard',
    name: 'Dashboard',
    Icon: Dashboard,
    path: '/instructors',
    role: 'c::sidenav::dashboard::instructor',
  },
  {
    id: 'Scheduling',
    name: 'Schedule',
    Icon: Calendar,
    path: '/scheduling',
    role: 'c::sidenav::calendar::student',
  },
  {
    id: 'InstructorScheduling',
    name: 'Schedule',
    Icon: Calendar,
    path: '/instructors/scheduling',
    role: 'c::sidenav::calendar::instructor',
  },
  {
    id: 'Events',
    name: 'Events',
    Icon: CalendarFavorite,
    path: '/events',
    role: 'c::sidenav::events',
  },
  {
    id: 'SchedulingInstructors',
    name: 'Instructors',
    Icon: SchoolTeacher,
    path: '/scheduling/instructors',
    role: 'c::sidenav::scheduling::instructors',
    longText: true,
  },
  {
    id: 'SFStandardFMI',
    name: 'Instructors',
    Icon: SchoolTeacher,
    path: '/sfstandard/instructors',
    role: 'c::sidenav::instructors::sfstandard',
    longText: true,
    queryParams: ['sid'],
    hideRoutes: ['SFStandardStudentProgress'],
  },
  {
    id: 'SFStandardStudentProgress',
    name: 'Student Progress',
    Icon: PerformanceIncrease1,
    path: '/sfstandard/student-progress',
    role: 'c::sidenav::student-progress::sfstandard',
    longText: true,
    queryParams: ['sid'],
    hideRoutes: ['SFStandardFMI'],
  },
  {
    id: 'SalesforceFMI',
    name: 'Instructors',
    Icon: SchoolTeacher,
    path: '/salesforce/instructors',
    role: 'c::sidenav::instructors::salesforce',
    longText: true,
    queryParams: ['sid'],
    hideRoutes: ['SalesforceStudentProgress'],
  },
  {
    id: 'SalesforceStudentProgress',
    name: 'Student Progress',
    Icon: PerformanceIncrease1,
    path: '/salesforce/student-progress',
    role: 'c::sidenav::student-progress::salesforce',
    longText: true,
    queryParams: ['sid'],
    hideRoutes: ['SalesforceFMI'],
  },
  {
    id: 'LearningPath',
    name: 'Learning path',
    Icon: TargetPath,
    path: '/learning-path',
    role: 'c::sidenav::learningPath',
  },
  {
    id: 'MyMedia',
    name: 'My Media',
    Icon: Bookmark,
    path: '/my-media',
    role: 'c::sidenav::myMedia',
  },
  {
    id: 'InstructorMyMedia',
    name: 'My Media',
    Icon: BookCloseBookmark1,
    path: '/instructors/my-media',
    role: 'c::sidenav::myMedia::instructor',
  },
  {
    id: 'StudentMyProgress',
    name: 'My Progress',
    Icon: PerformanceIncrease1,
    path: '/my-progress',
    role: 'c::sidenav::myProgress::student',
  },
  {
    id: 'Admin',
    name: 'Admin',
    Icon: Admin,
    path: '/admin',
    role: 'c::sidenav::admin',
  },
  {
    id: 'Support',
    name: 'Support',
    Icon: Help,
    path: '/support/contact-us',
    dataE2e: 'support-nav-btn',
  },
]
